<template>
  <div>
    <InputNumber
      v-model="minModel"
      :min="min"
      :max="leftMax"
      :active-change="false"
    />
    --
    <InputNumber
      v-model="maxModel"
      :min="rightMin"
      :max="max"
      :active-change="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    minLevel: {
      required: true,
    },
    maxLevel: {
      required: true,
    },
    min: {
      default: 0,
    },
    max: {
      default: 2000,
    },
  },
  computed: {
    minModel: {
      get() {
        return this.minLevel;
      },
      set(val) {
        this.$emit("update:minLevel", val);
      },
    },
    maxModel: {
      get() {
        return this.maxLevel;
      },
      set(val) {
        this.$emit("update:maxLevel", val);
      },
    },
    leftMax() {
      return this.maxModel ?? this.max;
    },
    rightMin() {
      return this.minModel ?? this.min;
    },
  },
};
</script>

<style>
</style>